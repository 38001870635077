import * as React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Modal,
} from "@material-ui/core";
import { getUserInfo } from "../services/getUserInfo";
import useStyles from "../assests/css/profileStyle";
import "../assests/css/style.css";
import Logo from "../assests/images/habilelabs_full_logo.svg";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { getUserImage, uploadImageName } from "../api";
import { CModal, CroppieModal } from ".";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Header } from "../../src/component/HeaderWithoutBack";

const EditProfile = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [userDetails, setUserDetails] = React.useState({});
  const name = userDetails.email && userDetails.email.split("@")[0];
  const [userName, setUserName] = React.useState("");
  const [openName, setOpenName] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userNameNew, setUserNameNew] = React.useState("");
  // const [showError, setShowError] = React.useState(false);
  const [showImageError, setImageShowError] = React.useState(false);
  const [croppedImage, setCroppedImage] = React.useState("");
  const handleOpenName = () => {
    setUserNameNew(userName);
    setOpenName(true);
  };
  const handleCloseName = () => setOpenName(false);
  const [img, setImg] = React.useState(localStorage.getItem("img"));
  // const [check, setCheck] = React.useState(true);
  const [imageUpload, setImageUpload] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState(null);
  // const [newState, setNewState] = useState("");
  // const id = btoa(name && name.replace(".", ""));

  const handleCloseImageUpload = () => {
    setImageUpload(false);
  };

  const handleImg = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        if (file.size > 2500000) {
          return setImageShowError("Image should be only max 2MB");
        }
        setImageUpload(true);
        setImageShowError(false);
        setSelectedImg(file);
      } else {
        return setImageShowError("Only images are allow");
      }
    }
    // if (file) {
    //   getBase64(file).then((base64) => {
    //     localStorage["img"] = base64;
    //     saveImageAndUserName(userName, base64.split(",")[1]);
    //     setCheck(!check);
    //   });
    // }
  };

  React.useEffect(() => {
    // console.log(localStorage.getItem("img"));
    getUserDetails();
  }, []);
  React.useEffect(() => {
    localStorage.setItem("BottomNavigationScreenIndex", 2);

    // bottomTabValueHandler(2);
    // console.log("bottomTabValueHandler")
    // localStorage.setItem("BottomNavigationScreenIndex", 0);
    // return () => {
    //   localStorage.setItem("BottomNavigationScreenIndex", 0);
    // };
  }, []);

  React.useEffect(() => {
    if (name) {
      getUserEmailUserName();
    }
  }, [name]);
  // React.useEffect(() => {
  //   getUserEmailUserName();
  // }, [img]);

  // React.useEffect(() => {
  //   console.log("useEffectuseEffect==>>>>>>>>>", localStorage.getItem("img"));
  //   setImg(localStorage.getItem("img"));
  // }, [img]);

  React.useEffect(() => {
    setImg(localStorage.getItem("img"));
  }, [img]);

  const getUserDetails = async () => {
    const details = await getUserInfo();
    setUserDetails(details);
  };

  // const signOutBtn = () => {
  //   bottomTabValueHandler(0);
  //   Auth.signOut();
  // };

  const changeUserName = () => {
    setUserName(userNameNew);
    const imageBase64 =
      localStorage.getItem("img") && localStorage.getItem("img").split(",")[1];
    saveImageAndUserName(userNameNew, imageBase64);
    handleCloseName();
  };

  // const saveImageAndUserName = (updateName, image) => {
  //   const userName = name + "_" + updateName;

  //   uploadImageName(userName, image).then((res) => {
  //     if (res.status === 200) {
  //       const data = res.data.files.link;
  //       setImg(data);
  //     }
  //   });
  // };
  const saveImageAndUserName = async (updateName, image) => {
    const userName = name + "_" + updateName;
    // console.log("saveImageAndUserName");
    const out = await uploadImageName(userName, image);
    // localStorage.setItem("img", out.files.link);

    // getUserEmailUserName();
  };

  // const enterDetails = () => {
  //   setUserName(userNameNew);
  //   // handleCloseImgName();
  // };

  const getUserEmailUserName = async () => {
    setIsLoading(true);
    const imageData = await getUserImage(name);
    // console.log("imageData", imageData.files.link);
    setImg(imageData.files.link);
    const data = imageData.files.link;
    setUserName(
      data
        .substr(data.lastIndexOf("/") + 1)
        .split(".")[0]
        .split("_")[1]
    );

    if (imageData.files.link) {
      setIsLoading(false);
    }
  };
  // const getUserEmailUserName = () => {
  //   setIsLoading(true);
  //   getUserImage(name)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setIsLoading(false);
  //         const data = res.data && res.data.files.link;
  //         setImg(data);
  //         // setUserName(data.lastIndexOf("/") + 1);
  //         setUserName(
  //           data
  //             .substr(data.lastIndexOf("/") + 1)
  //             .split(".")[0]
  //             .split("_")[1]
  //         );
  //       } else setIsLoading(false);
  //     })
  //     .catch((err) => setIsLoading(false));
  // };

  // const handleCloseChangePassword = () => {
  // setOldPassword("");
  // setNewPassword("");
  // setConfirmPassword("");
  // setOpenChangePassword(false);
  // };

  const uploadImage = (base64) => {
    if (img && img.size > 2500000) {
      handleCloseImageUpload();
      return setImageShowError("Image should be only max 2MB");
    }
    setImageShowError(false);
    setImg(base64);
    // console.log("base64.split(", ")[1]", base64);
    localStorage.setItem("img", base64);
    saveImageAndUserName(userName, base64.split(",")[1]);
    handleCloseImageUpload();
    // getBase64(img).then((base64) => {
    //   // localStorage["img"] = base64;
    //   // console.log("base64", base64);
    //   setImg(base64);
    //   console.log("base64.split(", ")[1]", base64);
    //   localStorage.setItem("img", base64);
    //   saveImageAndUserName(userName, base64.split(",")[1]);
    //   handleCloseImageUpload();
    // });
  };

  // const uploadImage = () => {
  //     if (selectedImg.size > 2500000) {
  //         handleCloseImageUpload();
  //         return setImageShowError("Image should be only max 2MB");
  //     }
  //     setImageShowError(false);
  //     getBase64(selectedImg).then((base64) => {
  //         // localStorage["img"] = base64;
  //         // console.log("base64", base64);
  //         setImg(base64);
  //         // console.log("base64.split(", ")[1]", base64);
  //         localStorage.setItem("img", base64);
  //         saveImageAndUserName(userName, base64.split(",")[1]);
  //         handleCloseImageUpload();
  //     });
  //     // const uploadImage = (base64) => {
  //     // if (img.size > 2500000) {
  //     //   handleCloseImageUpload();
  //     //   return setImageShowError("Image should be only max 2MB");
  //     // }
  //     // setImg(base64);
  //     // console.log("base64.split(", ")[1]", base64);
  //     // localStorage.setItem("img", base64);
  //     // saveImageAndUserName(userName, base64.split(",")[1]);
  //     // handleCloseImageUpload();
  //     // getBase64(img).then((base64) => {
  //     //   // localStorage["img"] = base64;
  //     //   // console.log("base64", base64);
  //     //   setImg(base64);
  //     //   console.log("base64.split(", ")[1]", base64);
  //     //   localStorage.setItem("img", base64);
  //     //   saveImageAndUserName(userName, base64.split(",")[1]);
  //     //   handleCloseImageUpload();
  //     // });
  // };

  return (
    <div>
      <Grid container className={classes.main}>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.profile}>
        <Header />

          {/* <Box className={classes.rootLogo} style={{ height: "14vh" }}>
            <IconButton
              component="span"
              onClick={() => {
                history.goBack();
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <img src={Logo} alt="logo" />
          </Box> */}
          <Box className={classes.editProfile} style={{ height: "86vh" }}>
            <p>Edit your profile here</p>
            <img
              src={img}
              key={img}
              name="file"
              className="img-thumbnail"
              height={100}
              width={100}
              alt="profile"
            />
            <input
              id="selectImage"
              type="file"
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={handleImg}
              accept="image/x-png,image/gif,image/jpeg"
              className={classes.input}
            />
            <label
              className={classes.icon}
              style={{ marginTop: "-60px" }}
              htmlFor="selectImage"
            >
              {<PhotoCamera />}
            </label>

            {showImageError && <span>{showImageError}</span>}
            <CModal
              maxWidth="xs"
              open={imageUpload}
              handleClose={handleCloseImageUpload}
              bgClick={true}
            >
              {console.log("selectedImg", selectedImg)}
              <CroppieModal
                image={selectedImg}
                uploadImage={uploadImage}
                uploading={false}
                closeModal={handleCloseImageUpload}
                modalTitle={"Change profile picture"}
                error={showImageError}
                setImage={setCroppedImage}
              />
              <Grid container>
                <Grid item xs={6} align={"left"}>
                  <div className={classes.cancelBtn}>
                    <Button variant="outlined" onClick={handleCloseImageUpload}>
                      Cancel
                    </Button>
                  </div>
                </Grid>
                {console.log("croppedImage", croppedImage)}

                <Grid item xs={6} align={"right"}>
                  <div className={classes.uploadBtn}>
                    <Button
                      variant="outlined"
                      disabled={!selectedImg && !showImageError}
                      onClick={() => uploadImage(croppedImage)}
                    >
                      Upload
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CModal>
            <Box className={classes.userProfile}>
              <div style={{ paddingLeft: 15, paddingTop: "38px" }}>
                <Typography
                  className={classes.userName}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {userName}
                </Typography>
                <label>
                  <IconButton
                    color="primary"
                    aria-label="update name"
                    component="span"
                    onClick={handleOpenName}
                  >
                    <BorderColorOutlinedIcon color="primary" />
                  </IconButton>
                </label>
              </div>
            </Box>
            <Box
              style={{
                borderRadius: "12px",
                backgroundColor: "white",
                marginTop: "30px",
              }}
            >
              <Button
                onClick={() => {
                  history.goBack();
                }}
              >
                Done
              </Button>
            </Box>
          </Box>
          <Modal
            open={openName}
            onClose={handleCloseName}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className={classes.modal}
              style={{ backgroundColor: "#060543" }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={classes.modalHeader}
              >
                Enter new name
              </Typography>
              <TextField
                fullWidth
                className={classes.modalBody}
                required
                value={userNameNew}
                onChange={(e) => {
                  setUserNameNew(e.target.value);
                }}
              />
              <Box className={classes.modalFooter}>
                <Button
                  variant="text"
                  size="medium"
                  onClick={handleCloseName}
                  style={{
                    backgroundColor: "red",
                    margin: "13px",
                    color: "white",
                  }}
                >
                  cancel
                </Button>
                <Button
                  variant="text"
                  size="medium"
                  onClick={changeUserName}
                  disabled={!userNameNew}
                  style={{
                    backgroundColor: "#060543",
                    margin: "13px",
                    color: "white",
                  }}
                >
                  confirm
                </Button>
              </Box>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditProfile;
