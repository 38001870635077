import React, { useState, useRef } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

import useStyles from "../assests/css/styles";
import { useParams } from "react-router-dom";
import { Header } from "./HeaderWithoutBack";
import { Recorder } from "react-voice-recorder";

import "react-voice-recorder/dist/index.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AudioPlayer from "react-h5-audio-player";

import {
  Button,
  Grid,
  InputBase,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { getVoiceMessage } from "../api";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
toast.configure();
const initialState = {
  url: null,
  blob: null,
  chunks: null,
  duration: {
    h: 2,
    m: 2,
    ms: 2,
    s: 2,
  },
};

const Input = (props) => <components.Input {...props} isHidden={false} />;

const VoiceMessageDetails = (props) => {
  const classes = useStyles();
  let { id } = useParams();
  const deviceName = atob(id);
  const [audioDetails, setAudioDetails] = useState(initialState);
  const [base64String, setBase64String] = useState("");
  const [duration, setDuration] = useState({ hh: "00", mm: "00", ss: "00" });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState();
  const recorderControls = useAudioRecorder();
  const [audioUrl, setaudioUrl] = useState();
  const notify = () => {
    toast.info("Message sent to the patient", { autoClose: true });
  };
  const notifyError = () => {
    toast.error("Message is not sent to the patient", { autoClose: true });
  };
  const addAudioElement = (blob) => {
    console.log("audio", blob);
    const url = URL.createObjectURL(blob);
    setaudioUrl(url);
    console.log("audio", url);
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      setBase64String(reader.result);
    };
    // const audio = document.createElement("audio");
    // console.log("audio", audio);

    // audio.src = url;
    // audio.controls = true;
    // setAudioDetails(blob);
    // var reader = new FileReader();
    // reader.readAsDataURL(blob.blob);
    // reader.onloadend = function () {
    //   setBase64String(reader.result);
    // };
    // const url = URL.createObjectURL(blob);
    // const audio = document.createElement("audio");
    // audio.src = url;
    // audio.controls = true;
    // document.body.appendChild(audio);
    // console.log("audio", audio);
    // setAudioDetails(audio);
    // var reader = new FileReader();
    // reader.readAsDataURL(audio.blob);
    // reader.onloadend = function () {
    //   setBase64String(reader.result);
    // };
  };
  const sendVoiceMessage = () => {
    // (
    //   (duration.hh !== "00" || duration.mm !== "00" || duration.ss !== "00") &&
    //   ((message && message.length) ||
    //     (audioDetails.url && audioDetails.url.length))
    // )

    if (
      (duration.hh !== "00" || duration.mm !== "00" || duration.ss !== "00") &&
      ((message && message.length) && base64String )
    ) {
      setShowModal(true);
      setErrorMessage("");
      const payload = {
        Device_Name: deviceName.replace(/[.-]/g, ""),
        Time: `${duration.hh}:${duration.mm}:${duration.ss}`,
        Audio: base64String.split(",")[1],
        Message: message,
      };
      getVoiceMessage(payload).then((res) => {
        console.log("getVoiceMessage", res);
        // console.log("getVoiceMessage", res.response.status);
        setShowModal(false);

        if (res.data.status === 400) {
          notifyError();
          // setShowModal(false);
          handleReset();
        } else {
          notify();
          // setShowModal(false);
          handleReset();
        }
      });
    } else {
      setErrorMessage(
        "Please fill the voice message/text message and time duration"
      );
    }
  };

  const handleAudioStop = (data) => {
    console.log("audio", data);
    setAudioDetails(data);
    var reader = new FileReader();
    reader.readAsDataURL(data.blob);
    reader.onloadend = function () {
      setBase64String(reader.result);
    };
  };

  const handleBlur = (e) => {
    const cloneState = { ...duration };
    if (e.target.name === "hh") {
      if (!e.target.value) {
        cloneState.hh = "00";
        setDuration(cloneState);
      }
    } else if (e.target.name === "mm") {
      if (!e.target.value) {
        cloneState.mm = "00";
        setDuration(cloneState);
      }
    } else if (e.target.name === "ss") {
      if (!e.target.value) {
        cloneState.ss = "00";
        setDuration(cloneState);
      }
    }
    setError("");
  };
  const handleFocus = (e) => {
    const cloneState = { ...duration };
    if (e.target.name === "hh") {
      if (!parseInt(e.target.value)) {
        cloneState.hh = "";
        setDuration(cloneState);
      }
    } else if (e.target.name === "mm") {
      if (!parseInt(e.target.value)) {
        cloneState.mm = "";
        setDuration(cloneState);
      }
    } else if (e.target.name === "ss") {
      if (!parseInt(e.target.value)) {
        cloneState.ss = "";
        setDuration(cloneState);
      }
    }
    setError("");
  };

  const handleDurationChange = (e) => {
    const cloneState = { ...duration };
    if (e.target.name === "hh") {
      if (e.target.value >= 0 && e.target.value < 24) {
        cloneState.hh = parseInt(e.target.value);
        setDuration(cloneState);
        setError("");
      } else {
        setError("The value should be between 0 and 24");
      }
    } else if (e.target.name === "mm") {
      if (e.target.value >= 0 && e.target.value < 60) {
        cloneState.mm = parseInt(e.target.value);
        setDuration(cloneState);
        setError("");
      } else {
        setError("The value should be between 0 and 60");
      }
    } else if (e.target.name === "ss") {
      if (e.target.value >= 0 && e.target.value < 60) {
        cloneState.ss = parseInt(e.target.value);
        setDuration(cloneState);
        setError("");
      } else {
        setError("The value should be between 0 and 60");
      }
    }
  };

  const handleReset = () => {
    setaudioUrl("");
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        ms: 0,
        s: 0,
      },
    };
    setAudioDetails(reset);
    console.log("setAudioDetails:", "setAudioDetails");
    setDuration({ hh: "00", mm: "00", ss: "00" });
    setMessage("");
  };

  const options = [
    { label: "Your slot is delayed by 1 hr", value: 1 },
    { label: "Your slot is delayed by 2 hr", value: 2 },
    { label: "Your slot is delayed by 3 hr ", value: 3 },
  ];
  // React.useEffect(() => {
  //   console.log("audioDetails", audioDetails);
  // }, [audioDetails]);

  const onInputChange = (inputValue, { action }) => {
    if (action === "input-change") {
      setMessage(inputValue);
    }
  };

  const onChange = (option) => {
    setValue(option);
    setMessage(option ? option.label : "");
  };
  const handleListen = (e) => {
    console.log(`Current time: ${e.target.currentTime}`);
    console.log(`Duration: ${e.target.duration}`);
  };
  const [currentTime, setCurrentTime] = useState(0);
  const [audioduration, setaudioDuration] = useState(0);
  const audioRef = useRef(null);
  React.useEffect(() => {
    const audioElement =
      audioRef.current &&
      audioRef.current.audio &&
      audioRef.current.audio.current;

    const updateTime = () => {
      if (audioElement) {
        setCurrentTime(audioElement.currentTime);
      }
    };
    const updateDuration = () => {
      if (audioElement) {
        setaudioDuration(audioElement.duration);
      }
    };

    if (audioElement) {
      audioElement.addEventListener("timeupdate", updateTime);
      audioElement.addEventListener('canplaythrough', updateDuration);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener("timeupdate", updateTime);
        audioElement.removeEventListener('canplaythrough', updateDuration);
      }
    };
  }, []);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  const handleLoadMetadata = (meta) => {
    const { duration } = meta.target;
    console.log("handleLoadMetadata",duration);
  };

  // .rhap_time.rhap_totalTime {
  //   display: none;
  // }

  return (
    <div className={classes.main}>
      <Header />
      <div className={classes.voiceRecorder}>
        <div className={classes.audioRecorder}>
          <AudioRecorder
            // onRecordingComplete={addAudioElement}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            onRecordingComplete={(data) => addAudioElement(data)}
            recorderControls={recorderControls}
            // downloadOnSavePress={true}
            // downloadFileExtension="mp3"
          />
        </div>

        {/* <Recorder
          record={true}
          audioURL={audioDetails.url}
          showUIAudio
          handleAudioStop={(data) => handleAudioStop(data)}
          handleAudioUpload={sendVoiceMessage}
          handleReset={() => handleReset()}
        /> */}

        {audioUrl && (
          <div className={classes.audioPlayer}>
            {/* {audioUrl && (
              <AudioPlayer
                autoPlay={false}
                src={audioUrl}
                showJumpControls={false}
                preload="none"
                customAdditionalControls={[]}
                customVolumeControls={[]}
                // showJumpControls={false}
              />
            )} */}

            {/* <AudioPlayer
              autoPlay={true}
              autoPlayAfterSrcChange={false}
              // layout="horizontal"
              src={audioUrl}
              onPlay={(e) => console.log("onPlay")}
              // showSkipControls={true}
              showJumpControls={false}
              preload="none"
              customAdditionalControls={[]}
              customVolumeControls={[]}
              // preload="none"
              timeFormat="mm:ss"
              // onListen={handleListen}

            /> */}

           
              <div>
                <AudioPlayer
                  preload="none"
                  // onLoadedMetadata={handleLoadMetadata}
                  // ref={audioRef}
                  // ref={audioRef}
                  autoPlay={false}
                  // autoPlayAfterSrcChange={false}
                  // layout="horizontal"
                  src={audioUrl}
                  showJumpControls={false}
                  onListen={() => {}}
                  customAdditionalControls={[]}
                  customVolumeControls={[]}
                  timeFormat="mm:ss"
                  onLoadedMetadata={handleLoadMetadata}

                  // onListen={handleListen}
                />
                {/* <p>Current Time: {formatTime(currentTime)}</p>
                <p>Duration: {formatTime(audioduration)}</p> */}
              </div>
            )
          </div>
        )}
        <div>
          <Grid container spacing={0} className={classes.manageTime}>
            <Grid item sm={12}>
              <Typography style={{ color: "black" }}>Time Duration</Typography>
              <div className="durationPicker">
                <InputBase
                  value={duration.hh}
                  placeholder="HH"
                  name="hh"
                  type="number"
                  size="small"
                  onChange={handleDurationChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <InputLabel>hh</InputLabel>
                <InputBase
                  name="mm"
                  value={duration.mm}
                  placeholder="MM"
                  type="number"
                  size="small"
                  onChange={handleDurationChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <InputLabel>mm</InputLabel>
                <InputBase
                  value={duration.ss}
                  name="ss"
                  placeholder="SS"
                  type="number"
                  size="small"
                  onChange={handleDurationChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <InputLabel>ss</InputLabel>
              </div>
            </Grid>
            <div>
              {error && (
                <p style={{ color: "red", width: "100%", fontSize: 14 }}>
                  {error}
                </p>
              )}
            </div>
            <Grid item sm={12} style={{ marginTop: "10px" }}>
              <Typography style={{ color: "black" }}>Message</Typography>
              <div style={{ width: "47vh", textAlign: "left" }}>
                <CreatableSelect
                  options={options}
                  isClearable={true}
                  value={value}
                  inputValue={message}
                  onInputChange={onInputChange}
                  onChange={onChange}
                  controlShouldRenderValue={false}
                  components={{
                    Input,
                  }}
                />
              </div>
            </Grid>
            <div>
              {errorMessage && (
                <p style={{ color: "red", width: "100%", fontSize: 14 }}>
                  {errorMessage}
                </p>
              )}
            </div>
          </Grid>
        </div>
        <div style={{ marginTop: "35px" }}>
          <Button
            variant="contained"
            onClick={sendVoiceMessage}
            style={{
              color: "#fff",
              border: "4px solid #060543",
              cursor: "pointer",
              background: "#060543",
              borderRadius: "26px",
              marginTop: "-17px",
              right: "11px",
              textTransform: "capitalize",
            }}
          >
            {showModal ? "Uploading" : "Send"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleReset();
            }}
            style={{
              color: "#fff",
              border: "4px solid #d32f2f",
              cursor: "pointer",
              background: "#d32f2f",
              borderRadius: "26px",
              marginTop: "-17px",
              textTransform: "capitalize",
            }}
          >
            {"clear"}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default VoiceMessageDetails;
