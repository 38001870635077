import { CTimePicker } from "./CTimePicker";
import React, { useEffect, useState } from "react";
import useStyles from "../assests/css/styles";
import { useParams } from "react-router-dom";
import { Header } from "./HeaderWithoutBack";
import {
  Button,
  CircularProgress,
  Grid,
  InputBase,
  InputLabel,
  Typography,
  Box,
} from "@material-ui/core";
import dayjs from "dayjs";
import {
  saveUserPrefenceSetting,
  getDoctorFormUserPreference,
} from "../api";
import moment from "moment";
import { durationTimetoMinutes } from "../services/utils";
import TextField from "@mui/material/TextField";
// import Box from "@mui/material/Box";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@mui/material/Alert";
import DatePicker from "react-multi-date-picker"

const SettingDetails = (props) => {
  const classes = useStyles();
  let { id } = useParams();
  console.log("ididididiInitial", id);
  const deviceName = atob(id);
  const queue = deviceName.split("_")[2];
  const [time, setTime] = useState(dayjs());
  const [closingtime, setClosingTime] = useState(dayjs());
  const [values, setValues] = useState()
  const [totalDays, setTotalDays] = useState(0)
  const convertedIntoDateObject = values && values.map((bookedDate) => {
    return moment(new Date(bookedDate)).format('YYYY-MM-DD');
  });
  const [duration, setDuration] = useState({ hh: "00", mm: "00", ss: "00" });
  const [error, setError] = useState("");
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [allotmentNo, setAllotmentNo] = useState(1);
  const [registrationClosing, setregistrationClosing] = useState({
    hh: "00",
    mm: "00",
    ss: "00",
  });

  // const [registrationClosing, setregistrationClosing] = useState(
  //   moment("00:00 AM", "hh:mm a")
  //   // moment().hour(0).minute(0).second(0)
  // );

  const [formInputValue, setFormInputValue] = React.useState({
    Age: false,
    Gender: false,
    Address: false,
    DiseaseName: false,
    emergency: false,
  });
  const { name, Age, Gender, Address, DiseaseName, emergency } = formInputValue;
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    console.log("event.target.checked", event.target.checked);
    console.log("event.target.checked", event.target.name);
    setFormInputValue({
      ...formInputValue,
      [event.target.name]: event.target.checked,
    });
  };
  const handleChangeOnApiValue = (value) => {
    setFormInputValue({
      ...formInputValue,
      value: true,
    });
  };

  const handleValueChange = (value) => {
    if (value && value.$d) {
      setTime(value.$d);
      setError("");
    } else if (value === null) {
      setTime(dayjs().$d);
      setError("");
    }
  };
  const handleValueChangeClosingTime = (value) => {
    if (value && value.$d) {
      setClosingTime(value.$d);
      setError("");
    } else if (value === null) {
      setClosingTime(dayjs().$d);
      setError("");
    }
  };
  const handleRegistrationClosingTimeValueChange = (value) => {
    if (value && value.$d) {
      // setregistrationClosing(value.$d);
      setError("");
    } else if (value === null) {
      // setregistrationClosing("00:00 am");
      // setregistrationClosing(
      //   moment().set({ hour: 0, minute: 0, second: 0 }).format("HH:mm a")
      // );
      setError("");
    }
  };

  const handleTextAllotmentNo = (event) => {
    setAllotmentNo(event.target.value);
    setError("");
  };

  const handleDisableDays = (e) => {
    setTotalDays(e.target.value)
    setError("");
  }

  const handleDurationChange = (e) => {
    const cloneState = { ...duration };
    if (e.target.name === "hh") {
      if (e.target.value >= 0 && e.target.value < 24) {
        cloneState.hh = parseInt(e.target.value);
        setDuration(cloneState);
        setError("");
      } else {
        setError("The value should be between 0 and 24");
      }
    } else if (e.target.name === "mm") {
      if (e.target.value >= 0 && e.target.value < 60) {
        cloneState.mm = parseInt(e.target.value);
        setDuration(cloneState);
        setError("");
      } else {
        setError("The value should be between 0 and 60");
      }
    } else if (e.target.name === "ss") {
      if (e.target.value >= 0 && e.target.value < 60) {
        // console.log(e.target.value);
        cloneState.ss = parseInt(e.target.value);
        setDuration(cloneState);
        setError("");
      } else {
        setError("The value should be between 0 and 60");
      }
    }
  };
  const handleRegistrationChange = (e) => {
    const cloneState = { ...registrationClosing };
    if (e.target.name === "hh") {
      if (e.target.value >= 0 && e.target.value < 24) {
        cloneState.hh = parseInt(e.target.value);
        setregistrationClosing(cloneState);
        setError("");
      } else {
        setError("The value should be between 0 and 24");
      }
    } else if (e.target.name === "mm") {
      if (e.target.value >= 0 && e.target.value < 60) {
        cloneState.mm = parseInt(e.target.value);
        setregistrationClosing(cloneState);
        setError("");
      } else {
        setError("The value should be between 0 and 60");
      }
    } else if (e.target.name === "ss") {
      if (e.target.value >= 0 && e.target.value < 60) {
        // console.log(e.target.value);
        cloneState.ss = parseInt(e.target.value);
        setregistrationClosing(cloneState);
        setError("");
      } else {
        setError("The value should be between 0 and 60");
      }
    }
  };
  const handleBlur = (e) => {
    const cloneState = { ...duration };
    if (e.target.name === "hh") {
      if (!e.target.value) {
        cloneState.hh = "00";
        setDuration(cloneState);
      }
    } else if (e.target.name === "mm") {
      if (!e.target.value) {
        cloneState.mm = "00";
        setDuration(cloneState);
      }
    } else if (e.target.name === "ss") {
      if (!e.target.value) {
        cloneState.ss = "00";
        setDuration(cloneState);
      }
    }
    setError("");
  };
  const handleBlurRegistration = (e) => {
    const cloneState = { ...registrationClosing };
    if (e.target.name === "hh") {
      if (!e.target.value) {
        cloneState.hh = "00";
        setregistrationClosing(cloneState);
      }
    } else if (e.target.name === "mm") {
      if (!e.target.value) {
        cloneState.mm = "00";
        setregistrationClosing(cloneState);
      }
    } else if (e.target.name === "ss") {
      if (!e.target.value) {
        cloneState.ss = "00";
        setregistrationClosing(cloneState);
      }
    }
    setError("");
  };
  const handleFocus = (e) => {
    const cloneState = { ...duration };
    if (e.target.name === "hh") {
      if (!parseInt(e.target.value)) {
        cloneState.hh = "";
        setDuration(cloneState);
      }
    } else if (e.target.name === "mm") {
      if (!parseInt(e.target.value)) {
        cloneState.mm = "";
        setDuration(cloneState);
      }
    } else if (e.target.name === "ss") {
      if (!parseInt(e.target.value)) {
        cloneState.ss = "";
        setDuration(cloneState);
      }
    }
    setError("");
  };
  const handleFocusRegistrationClosing = (e) => {
    const cloneState = { ...registrationClosing };
    if (e.target.name === "hh") {
      if (!parseInt(e.target.value)) {
        cloneState.hh = "";
        setregistrationClosing(cloneState);
      }
    } else if (e.target.name === "mm") {
      if (!parseInt(e.target.value)) {
        cloneState.mm = "";
        setregistrationClosing(cloneState);
      }
    } else if (e.target.name === "ss") {
      if (!parseInt(e.target.value)) {
        cloneState.ss = "";
        setregistrationClosing(cloneState);
      }
    }
    setError("");
  };

  const handleSavePrefence = () => {
    // const output = `${registrationClosing.hh}:${registrationClosing.mm}:00`;
    // console.log("registrationClosing", output);
    // console.log("formInputValue", formInputValue);
    // console.log(
    //   "moment(registrationClosing).form",
    //   moment(registrationClosing).format("hh:mm a")
    // );

    var openTime = moment(time, "hh:mm a").format("HH:mm");
    var close = moment(closingtime, "hh:mm a").format("HH:mm");
    // console.log(close);
    // console.log(openTime);
    var timeDifference =
      moment.duration(close).asMinutes() -
      moment.duration(openTime).asMinutes();
    // console.log(timeDifference);
    if (timeDifference < 0) {
      timeDifference = 1440 - -1 * timeDifference;
    }
    // console.log(timeDifference);

    const data = {
      Device_Name: deviceName.replace(/[.-]/g, ""),
      Opening_Time: moment(time).format("hh:mm a"),
      Closing_Time: moment(closingtime).format("hh:mm a"),
      Allotments: allotmentNo,
      Queue_Duration: `${duration.hh}:${duration.mm}:00`,
      Block: `${registrationClosing.hh}:${registrationClosing.mm}:00`,
      Block_Dates: convertedIntoDateObject,
      Felxible_Days: totalDays
    };
    // console.log(data);
    // console.log("allotmentNo", allotmentNo);
    if (
      moment(time).format("hh:mm a") == moment(closingtime).format("hh:mm a")
    ) {
      setError("Closing_Time cannot be Same as Opening_Time");
      setTimeout(() => {
        setError("");
      }, 2500);
    } else if (
      durationTimetoMinutes(`${duration.hh}:${duration.mm}:${duration.ss}`) >
      timeDifference
    ) {
      setError("Duration cannot be Less Than Queue Duration");
      // setTimeout(() => {
      //   setError("");
      // }, 2500);

      // console.log(
      //   durationTimetoMinutes(`${duration.hh}:${duration.mm}:${duration.ss}`)
      // );
      // console.log(timeDifference);
      // console.log("error");
      // var openTime = moment(time, "hh:mm a").format("HH:mm");
      // var close = moment(closingtime, "hh:mm a").format("HH:mm");
      // console.log(
      //   moment.duration(close).asMinutes() -
      //     moment.duration(openTime).asMinutes()
      // );
      // setError("Duration cannot be 1");
      setIsLoading(false);
    } else if (allotmentNo == "") {
      setError("allotment cannot be null");
      setTimeout(() => {
        setError("");
      }, 1000);
    } else if (allotmentNo < 1) {
      setError("allotment cannot be Less than 1");
      setTimeout(() => {
        setError("");
      }, 1000);
    } 
    else if (totalDays < 1) {
      setError("opening days cannot be Less than 1");
      setTimeout(() => {
        setError("");
      }, 1000);
    }
    
    else if (
      durationTimetoMinutes(`${duration.hh}:${duration.mm}:${duration.ss}`) ===
      0
    ) {
      setError("Duration cannot be less than one");
      setIsLoading(false);
    }
    //  else if (registrationClosing.hh.length < 2  || registrationClosing.mm.length < 2) {
    //   setError("registrationClosing should be in 00:00 format only");
    //   setIsLoading(false);
    //   // console.log(
    //   //   "registrationClosing.hh.length",
    //   //   registrationClosing.hh.length
    //   // );
    //   // if (registrationClosing.hh.length == 2) {
    //   //   setError("registrationClosing cannot be less than one");
    //   //   setIsLoading(false);
    //   // }
    // }
    else {
      saveUserPrefenceSettingHandler();
      if (!error && dayjs(time).isValid()) {
        saveUserPrefenceSetting(data).then((res) => {
          if (res.message === "success") {
            setOpen(true);
            // setAlert({
            //   message: "Setting save successfully.",
            //   type: "success",
            // });
            // setTimeout(() => {
            //   // console.log("setTimeout");
            //   setAlert({
            //     message: "",
            //     type: "",
            //   });
            // }, 2000);
          } else {
            setAlert({
              message: "Someting error, Please try again.",
              type: "error",
            });
          }
          setIsLoading(false);
        });
      }
    }
  };
  const saveUserPrefenceSettingHandler = () => {
    if (!error) {
      // console.log("saveUserPrefenceSetting");
    }
  };

  const getUserPreference = async () => {
    const payload = {
      TableName: "User_Preference",
      Data: "",
      Find: "",
      Device_Name: deviceName.replace(/[.-]/g, ""),
    };
    // const responseData = await getDoctorFormUserPreference(deviceName);

    // console.log("getUserPreferenceresponseData", responseData);
    const deviceNameName = deviceName.replace(/[.-]/g, "");
    const resigtrationClosingTimeWIth = moment()
      .set({ hour: 0, minute: 0, second: 0 })
      .format("HH:mm a");
    getDoctorFormUserPreference(deviceNameName).then((res) => {
      console.log("res", res);
      if (res.data) {
        // console.log("res.Data.Closing_Time", res.Data.Closing_Time);
        // setregistrationClosing( moment().set({ hour: 0, minute: 0, second: 0 }).format("HH:mm a"));
        setTime(dayjs(res.data.Opening_Time, "hh:mm a").$d);
        setClosingTime(dayjs(res.data.Closing_Time, "hh:mm a").$d);
        setAllotmentNo(res.data.Allotments);
        // Allotments
        const durationData = res.data.Queue_Duration.split(":");
        let durationObject = {
          hh: durationData[0],
          mm: durationData[1],
          ss: durationData[2],
        };
        setDuration(durationObject);
        const registrationClosingObjectData = res.data.Block_By.split(":");
        let closingObject = {
          hh: registrationClosingObjectData[0],
          mm: registrationClosingObjectData[1],
          ss: registrationClosingObjectData[2],
        };
        setregistrationClosing(closingObject);
        const blockedDates = res.data.Block_Dates
        const bookedDate = blockedDates && blockedDates.map((date) => {
          return new Date(date);
        });
        setValues(bookedDate)

        const addBlockDays = res.data.Felxible_Days
        setTotalDays(addBlockDays)

        // for (let i = 0; i <= trueValue.length; i++) {
        //   console.log("trueValue", trueValue[i]);
        //   const dataTrue = trueValue[i];
        //   setFormInputValue((oldValue) => ({ ...oldValue, [dataTrue]: true }));

        // }
        // for (let i = 0; i < trueValue.length; i++) {
        //   console.log("trueValue", trueValue[i]);
        //   const dataTrue = trueValue[i];
        //   setFormInputValue((oldValue) =>
        //     {
        //       ...oldValue,
        //       [dataTrue]: true,
        //     });
        // }
        // trueValue.map((item) => {
        // setFormInputValue({
        //   ...formInputValue,
        //   item: true,
        // });

        // });
      }
      setIsLoading(false);
    });
  };

  const registrationClosingInitialHandler = () => {
    setregistrationClosing("00:00 am");
  };
  useEffect(() => {
    setIsLoading(true);
    getUserPreference();
  }, []);
  // useEffect(() => {}, [registrationClosing]);

  console.log("moment().startOf('day')", registrationClosing);
  // console.log(
  //   "moment().startOf('day')",
  //   moment("00:00 am", "hh:mm a").format("hh:mm a")
  // );
  // const timeoutput = moment().set({ hour: 0, minute: 0, second: 0 });

  // // Format the time as a string
  // const formattedTime = timeoutput.format("HH:mm A");

  console.log(
    "moment().startOf('dayy')",
    moment().set({ hour: 0, minute: 0, second: 0 }).format("HH:mm a")
  );
  return (
    <div className={classes.main}>
      <Header />
      {!isLoading ? (
        <div className={classes.root}>
          <Grid container spacing={1} className={classes.manageQueue}>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={open}
              autoHideDuration={2500}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="success">
                Setting save successfully
              </Alert>
            </Snackbar>
            <Grid
              item
              sm={12}
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Typography variant="h5">
                <Box sx={{ fontWeight: "bold", m: 1, typography: "body1" }}>
                  Queue Name: {queue}
                </Box>
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography>Queue Opening Time</Typography>

              <CTimePicker time={time} handleValueChange={handleValueChange} />
            </Grid>
            <Grid item sm={12}>
              <Typography>Queue Closing Time</Typography>
              <CTimePicker
                time={closingtime}
                handleValueChange={handleValueChangeClosingTime}
              />
            </Grid>
            {/* <Grid item sm={12}>
              <Typography>Registration Close Time</Typography>

              <CTimePicker
                time={registrationClosing}
                handleValueChange={handleRegistrationClosingTimeValueChange}
              />
            </Grid> */}
            <Grid item sm={12} style={{ marginRight: "25px" }}>
              <Typography>Allotment Device</Typography>
              <TextField
                sx={{
                  "& fieldset": { border: "none" },
                }}
                value={allotmentNo}
                onChange={handleTextAllotmentNo}
                type={"number"}
                InputProps={{
                  inputProps: { min: 1 },
                }}
              />
            </Grid>
            {/* <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button> */}
            <Grid item sm={12}>
              <Typography>Registration Closing Time</Typography>
              <div className="durationPicker">
                <InputBase
                  value={registrationClosing.hh}
                  placeholder="HH"
                  name="hh"
                  type="number"
                  size="small"
                  onChange={handleRegistrationChange}
                  onBlur={handleBlurRegistration}
                  onFocus={handleFocusRegistrationClosing}
                />
                <InputLabel>HH</InputLabel>
                <InputBase
                  name="mm"
                  value={registrationClosing.mm}
                  placeholder="MM"
                  type="number"
                  size="small"
                  onChange={handleRegistrationChange}
                  onBlur={handleBlurRegistration}
                  onFocus={handleFocusRegistrationClosing}
                />
                <InputLabel>MM</InputLabel>
                {/* <InputBase
                  value={duration.ss}
                  name="ss"
                  placeholder="SS"
                  type="number"
                  size="small"
                  onChange={handleDurati}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <InputLabel>Sec</InputLabel> */}
              </div>
            </Grid>

            <Grid item sm={12}>
              <Typography>Time Duration</Typography>
              <div className="durationPicker">
                <InputBase
                  value={duration.hh}
                  placeholder="HH"
                  name="hh"
                  type="number"
                  size="small"
                  onChange={handleDurationChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <InputLabel>Hour</InputLabel>
                <InputBase
                  name="mm"
                  value={duration.mm}
                  placeholder="MM"
                  type="number"
                  size="small"
                  onChange={handleDurationChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <InputLabel>Min</InputLabel>
                {/* <InputBase
                  value={duration.ss}
                  name="ss"
                  placeholder="SS"
                  type="number"
                  size="small"
                  onChange={handleDurati}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                <InputLabel>Sec</InputLabel> */}
              </div>
            </Grid>
            <Grid item sm={12}>
              <Typography>Queue Opening Days</Typography>
              <TextField
                sx={{
                  "& fieldset": { border: "none" },
                }}
                value={totalDays}
                onChange={handleDisableDays}
                type={"number"}
                InputProps={{
                  inputProps: { min: 1 },
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <Typography>Disable Dates</Typography>
              <DatePicker
                multiple
                value={values}
                onChange={setValues}
                inputClass="customInput"
                format="DD MMMM"
                className="customCalendar"
                minDate={moment().add(0, "days").format("YYYY-MM-DD")}
              />
            </Grid>

            <Grid className="errorMessage">
              {error && <Typography color="secondary">{error}</Typography>}
            </Grid>
            {/* <Button
              variant="contained"
              fullWidth
              disabled={!dayjs(time).isValid()}
              onClick={handleSavePrefence}
            >
              Save
            </Button> */}
          </Grid>
          <Grid container spacing={1} className={classes.manageQueue}>
            {/* {alert.message !== "" && (
              <CAlert
                message={alert.message}
                type={alert.type}
                setAlert={setAlert}
              />
            )} */}
            <Button
              variant="contained"
              fullWidth
              disabled={!dayjs(time).isValid()}
              onClick={handleSavePrefence}
            >
              Save
            </Button>
          </Grid>
        </div>
      ) : (
        <CircularProgress className={classes.loader} color="primary" />
      )}
    </div>
  );
};

export default SettingDetails;
